<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Based on the reaction profile shown here, answer the two questions below.</p>

      <p class="mb-4 pl-6">
        <img :src="rpImage" width="351px" height="201px" />
      </p>

      <p class="mb-n3">What type of reaction is this?</p>

      <v-radio-group v-model="inputs.reactionType">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 my-0 py-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>

      <p class="mb-2">What is the change in enthalpy for this reaction?</p>

      <calculation-input
        v-model="inputs.enthalpy"
        prepend-text="$\Delta\text{H:}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question76',
  components: {
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        reactionType: null,
        enthalpy: null,
      },
      options: [
        {text: 'Endothermic', value: 'endothermic'},
        {text: 'Exothermic', value: 'exothermic'},
      ],
    };
  },
  computed: {
    rpImagePos() {
      return this.taskState.getValueBySymbol('arrayVariable').content.value;
    },
    rpImage() {
      return `/img/assignments/reactionProfile${this.rpImagePos}.png`;
    },
  },
};
</script>
